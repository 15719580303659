<script setup>
import { stripTags } from '@/lib/html.js';
import { WdsIllustration } from '@wds/components';

const props = defineProps({
  sticky: {
    type: Boolean,
    required: false,
    default: true,
  },
  headline: {
    type: String,
    required: false,
    default: '',
  },
  subLabel: {
    type: String,
    required: false,
    default: '',
  },
  freeCancellationText: {
    type: String,
    required: false,
    default: '',
  },
  singleBar: {
    type: Boolean,
    required: false,
    default: false,
  },
  alternativeBar: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const DEFAULT_HERO = {
  mobile: 'a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hostels_s.jpg',
  tablet: 'a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hostels_m.jpg',
  desktop: 'a.hwstatic.com/image/upload/q_auto,f_auto/hw/location-images/type/hostels_l.jpg',
};

const isSticky = toRef(props, 'sticky');
const isSingleBar = toRef(props, 'singleBar');
const isAltBar = toRef(props, 'alternativeBar');

// ### DATA
const headerSearchForm = ref(null);

// ### STORE/PINIA
const locationStore = useLocationStore();
const locationInfo = locationStore?.getLocationInfo;

// ### COMPOSABLES
const { useImage } = useImages();
const {
  isExtraSmallScreen,
  isSmallOrMediumScreen,
} = useMq();

// ### COMPUTED
const getHeroPicture = computed(() => {
  const heroImage = locationInfo?.hero || DEFAULT_HERO;
  const heroImageForViewport = toValue(isExtraSmallScreen)
    ? heroImage?.mobile
    : toValue(isSmallOrMediumScreen)
      ? heroImage?.tablet
      : heroImage?.desktop;

  return heroImageForViewport ? `background-image: url(${useImage.getImage(heroImageForViewport)})` : null;
});

// ### METHODS
function calcStickyTrigger() {
  if (isSticky.value) {
    const headerSearchStickyPlaceholder = document.querySelector('.header-search-sticky');
    const heroSearchPlaceholder = document.querySelector('.search-form-placeholder');
    const searchForm = headerSearchForm.value?.$el;
    const headerSearch = document.querySelector('.hero-search-wrapper .hero-search');
    const headerSearchOffsetPosition = headerSearch?.getBoundingClientRect();
    const stickyTrigger = headerSearchOffsetPosition?.top <= headerSearchOffsetPosition?.height * -1;
    if (headerSearchStickyPlaceholder && headerSearchStickyPlaceholder?.children?.length === 0 && stickyTrigger) {
      headerSearchStickyPlaceholder.classList.toggle('visible', true);
      headerSearchStickyPlaceholder.appendChild(searchForm);
      heroSearchPlaceholder.innerHTML = '';
    } else if (
      headerSearchStickyPlaceholder
      && heroSearchPlaceholder
      && !stickyTrigger
      && heroSearchPlaceholder?.children?.length === 0
    ) {
      headerSearchStickyPlaceholder.classList.toggle('visible', false);
      headerSearchStickyPlaceholder.innerHTML = '';
      heroSearchPlaceholder.appendChild(searchForm);
    }
  }
}

onMounted(() => {
  calcStickyTrigger();
  window.addEventListener('scroll', calcStickyTrigger, { passive: true });
});

onUnmounted(() => {
  window.removeEventListener('scroll', calcStickyTrigger);
});
</script>

<template>
  <header
    :class="{
      'single-bar': isSingleBar,
      'alternative': isAltBar,
    }"
  >
    <CommonHwNavBar
      :single-bar="isSingleBar"
      :alternative-bar="alternativeBar"
    />
    <div
      v-if="!isSingleBar && isSticky"
      class="header-search-sticky"
    />
    <div
      v-if="!isSingleBar"
      class="header-hero"
    >
      <div
        class="hero-image"
        :style="getHeroPicture"
      >
        <div class="hero-image-overlay" />
        <div class="hero-image-headline">
          <h1
            v-if="headline"
            class="headline-main-label"
          >
            {{ stripTags(headline) }}
          </h1>
          <span
            v-if="subLabel"
            class="headline-sub-label"
          >{{ stripTags(subLabel) }}</span>
        </div>
      </div>
      <div class="hero-search-wrapper">
        <div class="hero-search">
          <div class="search-form-placeholder">
            <CommonHwSearchForm ref="headerSearchForm" />
          </div>
          <div
            v-if="freeCancellationText"
            class="header-free-canx-notice"
          >
            <WdsIllustration
              asset="calendar-confirmation"
              folder="sm"
            />
            <span>{{ freeCancellationText }}</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
$header-hero-image-host: 'https://a.hwstatic.com/image/upload/v1675960377/pwa/';
$header-hero-image-mobile: $header-hero-image-host + 'bg.mobile.svg';
$header-hero-image-desktop: $header-hero-image-host + 'bg.desktop.svg';

header {
  position: relative;
  max-width: wds-rem(1600px);
  margin: 0 auto $wds-spacing-xl;
  z-index: $wds-z-index-dropdown;

  .header-search-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: initial;
    margin: 0;
    border-bottom-left-radius: $wds-border-radius-xxl;
    border-bottom-right-radius: $wds-border-radius-xxl;
    height: 0;
    background-repeat: no-repeat;
    background-image: url($header-hero-image-mobile);
    background-size: cover;
    background-position: 50% 50%;
    z-index: $wds-z-index-overlay;
    transition: height 100ms linear;

    &.visible {
      height: wds-rem(163px);
      padding: $wds-spacing-m;
    }
  }

  .header-hero {
    position: relative;
    margin: 0 $wds-spacing-m;

    .hero-image {
      position: relative;
      height: wds-rem(240px);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      background-color: $wds-color-ink-lighter;
      border-radius: $wds-border-radius-xl $wds-border-radius-xl 0 0;
    }

    .hero-image-overlay {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: wds-rem(240px);
      background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0, rgb(0 0 0 / 95%) 100%);
      border-radius: $wds-border-radius-xl $wds-border-radius-xl 0 0;
    }

    .hero-image-headline {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: $wds-spacing-m;
      color: $wds-color-white;
      text-align: center;
      display: flex;
      flex-direction: column;

      .headline-main-label {
        @include title-3-bld;
      }

      .headline-sub-label {
        @include body-2-bld;
      }
    }

    .hero-search-wrapper {
      position: relative;
      z-index: 1;
      flex: 1;

      .hero-search {
        background-repeat: no-repeat;
        background-image: url($header-hero-image-mobile);
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 0 0 $wds-border-radius-xl $wds-border-radius-xl;
        padding: $wds-spacing-l $wds-spacing-m;
        box-shadow: 0 $wds-spacing-m $wds-spacing-xl (-$wds-spacing-m) rgb(204 0 116);

        .search-form-placeholder {
          max-width: wds-rem(1104px);
          margin: 0 auto;
        }
      }

      .header-free-canx-notice {
        margin-top: $wds-spacing-m;
        margin-inline: auto;
        padding-inline: $wds-spacing-xl;
        max-width: wds-rem(1104px);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $wds-spacing-s;
        color: $wds-color-white;

        @include body-3-reg;
      }
    }
  }

  &.single-bar {
    max-width: none;
    margin: 0;
  }

  @include tablet {
    .header-hero {
      margin-inline: $wds-spacing-l;
    }
  }

  @include tablet-large {
    .header-search-sticky {
      background-image: url($header-hero-image-desktop);

      &.visible {
        height: wds-rem(98px);
        padding: $wds-spacing-m wds-rem(40px);
      }
    }

    .header-hero {
      .hero-image {
        height: wds-rem(360px);

        .hero-image-overlay {
          height: wds-rem(360px);
        }

        .hero-image-headline {
          .headline-main-label {
            @include title-1-bld;
          }

          .headline-sub-label {
            @include body-1-bld;
          }
        }
      }

      .hero-search-wrapper .hero-search {
        padding-inline: $wds-spacing-xl;
        background-image: url($header-hero-image-desktop);

        .search-greeting {
          @include title-1-bld;
        }
      }
    }
  }

  @include desktop {
    .header-hero {
      margin: 0 wds-rem(40px);

      .hero-search-wrapper {
        .hero-search {
          padding-inline: $wds-spacing-xxl;

          .search-form-placeholder {
            height: 66px;
          }
        }
      }
    }
  }
}
</style>
